import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3068df1a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 0,
  class: "active-template flex justify-content-center px-4"
}
const _hoisted_6 = { class: "flex-1" }
const _hoisted_7 = { class: "mb-5" }
const _hoisted_8 = { class: "flex align-items-center mb-3" }
const _hoisted_9 = { for: "alias" }
const _hoisted_10 = { class: "flex align-items-center mb-3" }
const _hoisted_11 = { for: "title" }
const _hoisted_12 = { class: "flex flex-column mb-3" }
const _hoisted_13 = {
  for: "body",
  class: "mb-1"
}
const _hoisted_14 = { class: "flex justify-content-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mdicon = _resolveComponent("mdicon")!
  const _component_PrimaryButton = _resolveComponent("PrimaryButton")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.templates, (template) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["submenu-item", { 'template-active': template.active }]),
            onClick: ($event: any) => ($setup.openTemplate(template))
          }, [
            (template.alias)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(template.alias), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString($props.text.emptyTemplate), 1)),
            (template.organizationId && !template.userId)
              ? (_openBlock(), _createBlock(_component_mdicon, {
                  key: 2,
                  name: "domain"
                }))
              : _createCommentVNode("", true),
            (template.organizationId && template.userId)
              ? (_openBlock(), _createBlock(_component_mdicon, {
                  key: 3,
                  name: "account"
                }))
              : _createCommentVNode("", true)
          ], 10, _hoisted_2))
        }), 256)),
        _createElementVNode("div", null, [
          _createVNode(_component_PrimaryButton, {
            onClick: $setup.newTemplate,
            icon: 'plus',
            label: $props.text.newTemplate
          }, null, 8, ["onClick", "label"])
        ])
      ]),
      ($setup.activeTemplate)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("form", _hoisted_6, [
              _createElementVNode("h1", _hoisted_7, _toDisplayString($props.text.editTemplate), 1),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("label", _hoisted_9, _toDisplayString($props.text.alias) + ":", 1),
                _createVNode(_component_InputText, {
                  class: "flex-1",
                  id: "alias",
                  modelValue: $setup.v$.alias.$model,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.v$.alias.$model) = $event)),
                  placeholder: $props.text.alias,
                  disabled: 
              !$setup.activeTemplate.computerId && !$setup.activeTemplate.userId && !$setup.activeTemplate.organizationId
            
                }, null, 8, ["modelValue", "placeholder", "disabled"])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("label", _hoisted_11, _toDisplayString($props.text.title) + ":", 1),
                _createVNode(_component_InputText, {
                  class: "flex-1",
                  id: "title",
                  modelValue: $setup.v$.title.$model,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.v$.title.$model) = $event)),
                  placeholder: $props.text.title,
                  disabled: 
              !$setup.activeTemplate.computerId && !$setup.activeTemplate.userId && !$setup.activeTemplate.organizationId
            
                }, null, 8, ["modelValue", "placeholder", "disabled"])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("label", _hoisted_13, _toDisplayString($props.text.body) + ":", 1),
                _createVNode(_component_Textarea, {
                  id: "body",
                  modelValue: $setup.v$.body.$model,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.v$.body.$model) = $event)),
                  "auto-resize": true,
                  rows: 10,
                  disabled: 
              !$setup.activeTemplate.computerId && !$setup.activeTemplate.userId && !$setup.activeTemplate.organizationId
            
                }, null, 8, ["modelValue", "disabled"])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", null, [
                  _createVNode(_component_Button, {
                    label: $props.text.save,
                    onClick: $setup.editTemplate,
                    disabled: 
                !$setup.v$.$anyDirty ||
                (!$setup.activeTemplate.computerId &&
                  !$setup.activeTemplate.userId &&
                  !$setup.activeTemplate.organizationId)
              
                  }, null, 8, ["label", "onClick", "disabled"]),
                  (
                $setup.v$.$anyDirty &&
                ($setup.activeTemplate.computerId ||
                  $setup.activeTemplate.userId ||
                  $setup.activeTemplate.organizationId)
              )
                    ? (_openBlock(), _createBlock(_component_Button, {
                        key: 0,
                        icon: "pi pi-undo",
                        class: "p-button-rounded p-button-secondary p-button-text",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.revertChanges()))
                      }))
                    : _createCommentVNode("", true)
                ]),
                _createVNode(_component_Button, {
                  label: $props.text.delete,
                  class: "p-button-danger",
                  onClick: $setup.deleteTemplate,
                  disabled: 
              !$setup.activeTemplate.computerId && !$setup.activeTemplate.userId && !$setup.activeTemplate.organizationId
            
                }, null, 8, ["label", "onClick", "disabled"])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_Dialog, {
      modal: true,
      visible: $setup.deleteTemplateDialog,
      "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => (($setup.deleteTemplateDialog) = $event)),
      header: $props.text.deleteHeader
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: $props.text.no,
          icon: "pi pi-times",
          autofocus: "",
          class: "p-button-text",
          onClick: _cache[4] || (_cache[4] = ($event: any) => ($setup.deleteTemplateDialog = false))
        }, null, 8, ["label"]),
        _createVNode(_component_Button, {
          label: $props.text.yes,
          icon: "pi pi-check",
          class: "p-button-danger",
          onClick: $setup.deleteTemplate
        }, null, 8, ["label", "onClick"])
      ]),
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString($props.text.deleteBody) + " ", 1)
      ]),
      _: 1
    }, 8, ["visible", "header"])
  ], 64))
}