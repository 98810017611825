
import PrimaryButton from "@/components/buttons/PrimaryButton.vue"
import useVuelidate from "@vuelidate/core"
import Button from "primevue/button"
import InputText from "primevue/inputtext"
import { reactive, ref, watch } from "vue"
import { useRoute, useRouter } from "vue-router"

export default {
  name: "EmailTemplates",
  props: {
    organizationId: {
      type: String,
      default: null,
    },
    endpoints: {
      type: Object,
      required: true,
    },
    language: {
      type: String,
    },
    emailType: {
      type: Object,
    },
    service: {
      type: Object,
    },
    newTemplateRoute: {
      type: String,
    },
    newlyAddedTemplateId: {
      type: String,
      default: null,
    },
    text: {
      type: Object,
      required: true,
    },
  },
  setup(props: any) {
    const templates = ref([])
    const activeTemplate = ref()
    const lastActivatedTemplateId = ref(null)
    const router = useRouter()
    const route = useRoute()
    const deleteTemplateDialog = ref(false)
    const newTemplateId = ref(props.newlyAddedTemplateId)
    const form = reactive({
      alias: "",
      title: "",
      body: "",
    })
    const rules = {
      alias: {},
      title: {},
      body: {},
    }
    const v$: any = useVuelidate(rules, form)
    loadTemplates()
    function reload() {
      activeTemplate.value = null
      lastActivatedTemplateId.value = null
      templates.value = []
      newTemplateId.value = null
      loadTemplates()
    }
    function loadTemplates() {
      if (props.language && props.emailType) {
        if (props.organizationId) {
          props.service
            .getMailTemplatesByLanguageAndType(
              props.endpoints.get_language_type.url,
              props.language,
              props.emailType.value,
              props.organizationId
            )
            .then((response: any) => {
              templates.value = response.data.filter(
                (template: any) => template.computerId || template.userId || template.organizationId
              )
              if (newTemplateId.value && templates.value.length) {
                openNewlyAddedTemplate(templates.value)
              }
            })
        } else {
          props.service
            .getMailTemplatesByLanguageAndType(
              props.endpoints.get_language_type.url,
              props.language,
              props.emailType.value
            )
            .then((response: any) => {
              templates.value = response.data.filter(
                (template: any) => template.computerId || template.userId || template.organizationId
              )
              if (newTemplateId.value && templates.value.length) {
                openNewlyAddedTemplate(templates.value)
              }
            })
        }
      }
    }
    function openNewlyAddedTemplate(templates: any) {
      templates.forEach((template: any) => {
        if (template.id === newTemplateId.value) {
          openTemplate(template)
        }
      })
    }
    function openTemplate(template: any) {
      templates.value.forEach((template) => {
        template.active = false
      })
      if (template.id !== lastActivatedTemplateId.value) {
        template.active = true
        lastActivatedTemplateId.value = template.id
      } else {
        activeTemplate.value = null
        template.active = false
        lastActivatedTemplateId.value = null
        return
      }
      activeTemplate.value = template
      form.alias = template.alias
      form.title = template.title
      form.body = template.body
      v$.value.$reset()
    }
    function newTemplate() {
      router.push({
        name: props.newTemplateRoute,
      })
    }
    function editTemplate() {
      v$.value.$touch()
      if (v$.value.$invalid) {
        return
      }
      const body = {
        alias: form.alias,
        language: props.language,
        title: form.title,
        body: form.body,
        type: props.emailType.value,
      }
      if (props.organizationId) {
        props.service
          .putMailTemplates(props.endpoints.put.url, [activeTemplate.value.id], body)
          .then((res: any) => {
            reload()
          })
      } else {
        props.service
          .putMailTemplate(props.endpoints.put.url, activeTemplate.value.id, body)
          .then((res: any) => {
            reload()
          })
      }
    }
    function deleteTemplate() {
      if (!deleteTemplateDialog.value) {
        deleteTemplateDialog.value = true
        return
      } else {
        deleteTemplateDialog.value = false
      }
      props.service
        .deleteMailTemplate(props.endpoints.delete.url, activeTemplate.value.id)
        .then((res: any) => {
          reload()
        })
    }
    function revertChanges() {
      form.alias = activeTemplate.value.alias
      form.title = activeTemplate.value.title
      form.body = activeTemplate.value.body
      v$.value.$reset()
    }
    watch(
      () => props.language,
      (newValue, oldValue) => {
        reload()
      }
    )
    watch(
      () => props.emailType,
      (newValue, oldValue) => {
        reload()
      }
    )
    return {
      templates,
      openTemplate,
      activeTemplate,
      form,
      v$,
      newTemplate,
      editTemplate,
      deleteTemplate,
      revertChanges,
      deleteTemplateDialog,
    }
  },
  components: { PrimaryButton },
}
